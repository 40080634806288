/*-------------------------
    Topbar
-------------------------*/
.topbar-global {
    padding: 10px 0; 
    min-height: 50px;
    display: flex;
    align-items: center;
    transition: var(--transition);
    &.color-dark-1-fixed {
        background-color: var(--color-woodsmoke);;   
        .news-feed-label {
            color: var(--color-light-1-fixed);
        }  
        .news-feed-slider {
            .single-slide {
                .link-wrap {
                    color: var(--color-light-1-fixed);
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        } 
        .current-date {
            color: var(--color-light-1-fixed);
            &:before {
                background-color: var(--color-border-light-1-fixed); 
            }  
        }
        .my_switcher {
            ul {
                li {        
                    button {
                        color: var(--color-light-1-fixed);
                    }
                }
            }
        } 
        .notification-wrap {        
            .dropdown {        
                .dropdown-toggle {
                    .icon-holder {
                        color: var(--color-light-1-fixed);
                    }
                }
            }        
            .has-notification {        
                &:before {
                    border-color: var(--color-light-1-fixed);
                }
            } 
        } 
    }
    &.color-light-1-fixed {
        background-color: var(--color-light-1-fixed);         
        .news-feed-slider {
            .single-slide {
                .link-wrap {
                    color: var(--color-dark-1-fixed);
                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        } 
        .current-date {
            color: var(--color-dark-1-fixed);
            &:before {
                background-color: var(--color-border-dark-1-fixed); 
            } 
        } 
        .my_switcher {
            ul {
                li {        
                    button {
                        color: var(--color-dark-1-fixed);
                    }
                }
            }
        } 
        .notification-wrap {        
            .dropdown {        
                .dropdown-toggle {
                    .icon-holder {
                        color: var(--color-dark-1-fixed);
                    }
                }
            }         
            .has-notification {        
                &:before {
                    border-color: var(--color-dark-1-fixed);
                }
            } 
        } 
    }
}

.social-layout-1 {
    display: flex;
    align-items: center;
    column-gap: 26px;
    label {
        color: var(--color-light-1-fixed); 
        position: relative;
        font-size: 14px;
        transition: var(--transition);
        &:before {
            position: absolute;
            height: 20px;
            width: 1px;
            content: "";
            background-color: var(--color-border-light-1-fixed); 
            right: -18px;
            top: 50%;
            transform: translateY(-50%);
            transition: var(--transition);
        } 
    }
    ul {
        display: flex;
        align-items: center;
        column-gap: 5px;
        li {
            a {
                font-size: 15px;
                color: var(--color-light-1-fixed);
                display: block;
                height: 25px;
                width: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: var(--transition);
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}

.news-feed-wrap {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 14px;
    margin-top: 1px;
    .news-feed-label {
        color: var(--color-dark-1-fixed);
        position: relative;
        padding-left: 10px;
        font-weight: 700;
        line-height: 1;
        &:before {
            content: "";
            background-color: var(--color-shamrock);
            height: 5px;
            width: 5px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            left: 0;
            position: absolute;
        }
    }
    .news-feed-slider {
        .single-slide {
            .link-wrap {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                transition: var(--transition);
            }
        }
    }
}

.my_switcher {
    ul {
        list-style: none;
        padding: 0;
        margin: 1px 0 0 0;
        height: 20px;
        width: 20px;
        border: 0;
        border-radius: 50%;
        position: relative;
        li {
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            button {
                background-color: transparent;
                border: 0;
                text-align: center;
                color: var(--color-light-1-fixed);
                font-size: 20px;
                display: inline-block;
                line-height: 1;

                &.active {
                    display: none;
                }
            }
        }
    }
}

.current-date {
    white-space: pre;  
    font-size: 14px;
    position: relative;
    transition: var(--transition);
    &:before {
        position: absolute;
        height: 20px;
        width: 1px;
        content: "";
        right: -12px;
        top: 50%;
        transform: translateY(-50%);
        transition: var(--transition);
    } 
}

.notification-wrap {
    display: flex;
    align-items: center;
    margin-top: 2px;
    &.dropdown-item-wrap {
        .navbar {
            padding: 0;
        }
        .dropdown-menu {
            margin-top: 11px;
            padding: 0;
            width: 330px;
            background-color: var(--color-light-1);
            border: 1px solid;
            border-color: var(--color-border-dark-1);
            border-radius: 8px;
            @media (max-width: 480px) {
                width: 250px;
            }
            .dropdown-menu-inner {
                padding: 20px 10px;

                &:before {
                    top: -18px;
                    right: 36px;
                }
                .notification-btn-wrap {
                    margin-top: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

    .dropdown {
        line-height: 1;

        .dropdown-toggle {
            .icon-holder {
                line-height: 1;
                display: block;
                font-size: 25px;
                color: var(--color-light-1-fixed);
                transition: var(--transition);
            }

            &:after {
                display: none;
            }
        }
    }

    .article-number {
        font-size: 16px;
        font-weight: 600;
        font-family: var(--font-secondary);
        margin-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
        color: var(--color-dark-1);
    }

    .has-notification {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: var(--color-primary);
            top: -1PX;
            right: 2px;
            transition: var(--transition);
        }
    }

    .notification-item {
        display: block;
        padding: 0 15px;
        border-bottom: 1px solid;
        border-color: var(--color-light-3);
        padding-top: 12px;
        padding-bottom: 12px;
        transition: var(--transition);

        &:hover {
            background-color: var(--color-light-2);
        }

        &:last-child {
            border-bottom: 0;
        }

        .post-box {        
            display: flex;
            align-items: center;
            gap: 10px;
            
            .figure-holder {
                position: relative;
                max-width: 80px;
                width: 100%;
                .figure-overlay:before {
                    display: none;
                }
            }
            .content-holder {
                flex: 1;
                .entry-title {
                    line-height: 1.5;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                }
            }
        }        
    }
}

.search-trigger-wrap {
    a {
        margin-top: 2px;
        padding: 5px 0;
        line-height: 1;
        display: block;
        font-size: 23px;
        color: var(--color-dark-1-fixed);
        transition: all 0.3s ease-in-out;
    }
}

.search-input-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: var(--color-light-1);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
    box-shadow: 0px 4px 25px 0px rgba(240, 241, 245, 0.8);
    transition: var(--transition);

    .search-form {
        position: relative;
        input[type="search"] {
            color: var(--color-dark-1);
            font-size: 20px;
            text-align: left;
            border: none;
            border-bottom: 1px solid;
            border-color: var(--color-light-2);
            margin: 0 auto;
            padding: 5px 30px 5px 0;
            outline: none;
            width: 100%;
            background-color: transparent;
        }

        input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 10px;
            background:
              linear-gradient(45deg, rgba(var(--color-black-rgb), 0) 0%,rgba(var(--color-black-rgb), 0) 43%,var(--color-black) 45%,var(--color-black) 55%,rgba(var(--color-black-rgb), 0) 57%,rgba(var(--color-black-rgb), 0) 100%),
              linear-gradient(135deg, transparent 0%,transparent 43%,var(--color-black) 45%,var(--color-black) 55%,transparent 57%,transparent 100%);
          }

        .search-btn {
            color: var(--color-dark-1);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            box-shadow: none;
            border: none;
            cursor: pointer;
            font-size: 24px;
            background-color: var(--color-light-1);
            transition: var(--transition);
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        input::-webkit-input-placeholder {
            color: var(--color-body);
        }

        input::-moz-placeholder {
            color: var(--color-body);
        }

        input:-moz-placeholder {
            color: var(--color-body);
        }

        input:-ms-input-placeholder {
            color: var(--color-body);
        }
    }

    .close {
        position: fixed;
        top: 5px;
        right: 10px;
        color: #000000;
        border: none;
        opacity: 0.3;
        visibility: visible;
        padding: 3px 15px 5px;
        font-size: 30px;
        font-weight: 300;
        cursor: pointer;
        transition: var(--transition);

        &:focus {
            box-shadow: none;
            border: none;
            outline: none;
        }

        &:hover {
            opacity: 1;
        }
    }

    &.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

.profile-wrap {
    &.dropdown-item-wrap {
        .dropdown-menu {
            margin-top: 26px;
            padding: 0;
            width: 290px;
            background-color: var(--color-light-1);
            border: 1px solid;
            border-color: var(--color-border-dark-1);
            border-radius: 8px;
            @media (max-width: 480px) {
                width: 220px;
            }
            .dropdown-menu-inner {
                padding: 30px 20px;

                &:before {
                    top: -18px;
                    right: 0;
                }
            }
        }
    }
    .navbar {
        padding: 0;
    }
    .dropdown {
        line-height: 1;

        .dropdown-toggle {
            &:after {
                display: none;
            }
        }
    }

    .thumble-holder {
        border: 1px solid;
        border-color: #6DAEFB;
        padding: 2px;
        border-radius: 50%;
        display: inline-block;
        height: 30px;
        width: 30px;

        img {
            border-radius: 50%;
        }
    }

    .profile-content {
        margin-bottom: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid;
        border-color: var(--color-light-3);

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                font-family: var(--font-secondary);
                margin-bottom: 23px;
                line-height: 1.5;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                    gap: 16px;
                    color: var(--color-dark-1);
                    transition: var(--transition);

                    &:hover {
                        color: var(--color-primary);
                    }

                    .icon-holder {
                        line-height: 1;
                        font-size: 24px;
                        margin-top: -3px;
                    }
                }
            }
        }

        &.with-icon {
            ul {
                li {
                    margin-bottom: 17px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.search-layout-1 {
    position: relative;
    max-width: 315px;
    width: 100%;

    .icon-holder {
        background-color: transparent;
        border: none;
        color: var(--color-dark-1-fixed);
        line-height: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 15px;
        transition: var(--transition);
    }

    input {
        font-weight: 400;
        font-size: 15px;
        color: var(--color-dark-1-fixed);
        background-color: var(--color-light-1-fixed);
        height: 42px;
        padding: 5px 45px;
        border: 0;
        border-radius: 45px;
        transition: var(--transition);

        &:focus {
            color: var(--color-dark-1-fixed);
            box-shadow: none;
            border: 0;
            background-color: var(--color-light-1-fixed);
        }
    }

    .btn-mic {
        color: var(--color-dark-1-fixed);
        line-height: 1;
        padding: 5px 10px;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        transition: var(--transition);

        &:hover {
            color: var(--color-primary);
        }
    }

    input::-webkit-input-placeholder {
        color: var(--color-body-fixed);
        font-weight: 400;
        font-size: 15px;
        font-family: var(--font-primary);
        transition: var(--transition);
    }

    input::-moz-placeholder {
        color: var(--color-body-fixed);
        font-weight: 400;
        font-size: 15px;
        font-family: var(--font-primary);
        transition: var(--transition);
    }

    input:-moz-placeholder {
        color: var(--color-body-fixed);
        font-weight: 400;
        font-size: 15px;
        font-family: var(--font-primary);
        transition: var(--transition);
    }

    input:-ms-input-placeholder {
        color: var(--color-body-fixed);
        font-weight: 400;
        font-size: 15px;
        font-family: var(--font-primary);
        transition: var(--transition);
    }
}
/*-------------------------
    Back To Top  
--------------------------*/
.back-to-top {
    position: fixed;
    bottom: -40px;
    right: 40px;
    display: flex;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: var(--color-primary);
    color: #fff;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    opacity: 0;
    font-size: 24px;
    transform: scale(0.3);
    box-shadow: 4px 4px 10px rgba(var(--color-black-rgb), 0.2);
    z-index: 9;
    transition: all .3s;
}

@media only screen and (max-width: 575px) {
    .back-to-top {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.back-to-top:focus {
    color: var(--color-white);
}

.back-to-top.show {
    bottom: 40px;
    right: 40px;
    opacity: 1;
    transform: scale(1);
}

@media only screen and (max-width: 575px) {
    .back-to-top.show {
        bottom: 10px;
        right: 10px;
    }
}

.back-to-top.show:hover {
    color: var(--color-white);
    bottom: 30px;
    opacity: 1;
}

@media only screen and (max-width: 575px) {
    .back-to-top.show:hover {
        bottom: 10px;
    }
}
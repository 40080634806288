/*-------------------------
    404
-------------------------*/
.error-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.error-box {
    text-align: center;

    .description {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
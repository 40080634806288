/*-------------------------
    Hero
-------------------------*/
.hero-wrap-layout1 {
    padding-top: 70px;
    padding-bottom: 90px;
    @media (max-width: 1199px) {
        padding-top: 60px;
        padding-bottom: 75px;
    }
    @media (max-width: 991px) {
        padding-top: 50px;
        padding-bottom: 60px;
    }
    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 45px;
    }
}
.hero-box-layout1 {
    text-align: center;
    .title {
        max-width: 1000px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        span {
            font-weight: 400;
        }
    }
    .search-form {
        margin-top: 20px;
        height: 60px;
        border-style: solid;
        border-width: 1px;
        border-color: var(--color-border-dark-1);
        background-color: var(--color-light-1);
        display: inline-flex;
        align-items: center;
        box-shadow: 5px 5px 0px 0px var(--color-border-dark-1);
        transition: var(--transition);
        @media (max-width: 991px) {
            margin-top: 10px;
        }
        @media (max-width: 767px) {
            display: inline-block;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            width: 100%;
            &:hover {
                box-shadow: none;
                transform: translateX(0) translateY(0);
            }
        }
        .email-input {
            background-color: transparent;
            border: 0;
            height: 52px;
            width: 484px;
            padding: 2px 25px;
            border-radius: 8px 0 0 8px;            
            color: var(--color-dark-1);

            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                width: 100%;
                height: 45px;
                border-radius: 8px; 
                background-color: var(--color-light-1-fixed); 
                margin-bottom: 5px;
                border: 1px solid;
                border-color: var(--color-border-dark-1-fixed);
            }
        }
        .axil-btn {
            font-size: 18px;
            padding: 5px 23px;
            height: 58px;
            border-top: 0;
            border-right: 0;
            border-bottom: 0;
            border-color: var(--color-border-dark-1);
            background-color: var(--color-btn-bg);
            border-radius: 0 8px 8px 0;
            @media (max-width: 767px) {
                width: 100%;
                height: 45px;
                border-radius: 8px;  
                border: 1px solid;
                border-color: var(--color-border-dark-1);
            }
        }
        input::-webkit-input-placeholder {
            color: var(--color-body);
        }

        input:-ms-input-placeholder {
            color: var(--color-body);
        }

        input:-moz-placeholder {
            color: var(--color-body);
        }
    }
}
/*-------------------------
    Breadcrumb
-------------------------*/
.breadcrumb-wrap-layout1 {
    padding: 20px 0;
    display: flex;
    align-items: center;
    min-height: 60px;
}
.breadcrumb-layout1 {
    .breadcrumb {
        margin: 0;
        .breadcrumb-item {
            a {
                color: var(--color-meta-dark-1-fixed);               
                transition: var(--transition);
                &:hover {
                    color: var(--color-primary);
                }
            }
            &.active {
                 color: var(--color-dark-1-fixed);
            }
        }
    }
}

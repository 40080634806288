/*-------------------------
    Author
-------------------------*/
.author-box-layout1 {
    display: flex;
    align-items: center;
    gap: 30px;
    @media (max-width: 575px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .figure-holder {
        max-width: 180px;
        width: 100%;
        border: 1px solid;
        border-color: var(--color-border-dark-1-fixed);
        border-radius: 50%;
        img {
            border-radius: 50%;
        }
    }
    .content-holder {
        .title {
            color: var(--color-dark-1);
            margin-bottom: 2px;
        }
        .designation {    
            color: var(--color-dark-1);        
            margin-bottom: 7px;
            position: relative;
            display: inline-block;
            z-index: 1;
            font-size: 18px;
            transition: var(--transition);
            &:before {
                content: "";
                height: 20px;
                width: 100%;
                position: absolute;
                z-index: -1;
                bottom: -1px;
                left: 0;
                background-color: var(--color-heading-1);
                transition: var(--transition);
            }
        }
        .description { 
            color: var(--color-body);     
            max-width: 820px;
            width: 100%;
            margin-bottom: 10px;
        }
        .axil-social {
            @media (max-width: 575px) {
                justify-content: center;
            }
        }
    }
}